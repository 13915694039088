import React from "react";
import "./scss/main.scss";
import * as Calc from "./Calc";
import AlertDialog from "./Dialog.js";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd"

// Icons
import ShareSharpIcon from "@mui/icons-material/ShareSharp";
import FileCopySharpIcon from "@mui/icons-material/FileCopySharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import DragHandleSharpIcon from "@mui/icons-material/DragHandleSharp";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";

// Reorder helper

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// App
class App extends React.Component {
  constructor(props) {
    super(props);

    // Default values

    const defaultValues = {
      dropchanceInPercent: 1,
      runs1: 10,
      runs2: 50,
      runs3: 100,
      targetProbability1: 50,
      targetProbability2: 90,
      targetProbability3: 99,
      shareModalActive: false,
      shareLinkCopied: false,
      dropchances: [],
    };

    // Check for url hash

    if (window.location.hash) {
      const hash = window.location.hash.substr(1);
      const hashArr = hash.split(";");

      const mapHashValueToState = {
        dc: "dropchanceInPercent",
        c1: "runs1",
        c2: "runs2",
        c3: "runs3",
        r1: "targetProbability1",
        r2: "targetProbability2",
        r3: "targetProbability3",
      };

      hashArr.forEach(function (hashValue) {
        const hashValueArr = hashValue.split(":");
        if (
          hashValueArr[0] &&
          hashValueArr[1] &&
          mapHashValueToState[hashValueArr[0]]
        ) {
          defaultValues[mapHashValueToState[hashValueArr[0]]] =
            hashValueArr[1].replace(",", ".");
        }
      });
    }

    defaultValues.dropchanceOnePerX = Calc.convertPercentToOnePerX(
      defaultValues.dropchanceInPercent
    );

    // Dropchance items
    if (localStorage.getItem("dropchances")) {
      defaultValues.dropchances = JSON.parse(
        localStorage.getItem("dropchances")
      );
    }

    this.state = defaultValues;

    this.handleDropchanceInPercentChange =
      this.handleDropchanceInPercentChange.bind(this);
    this.handleDropchanceOnePerX = this.handleDropchanceOnePerX.bind(this);
    this.handleTargetProbabilityChange =
      this.handleTargetProbabilityChange.bind(this);
    this.handleRunsChange = this.handleRunsChange.bind(this);
    this.handleMinusPercentButton = this.handleMinusPercentButton.bind(this);
    this.handlePlusPercentButton = this.handlePlusPercentButton.bind(this);
    this.handleMinusOnePerXButton = this.handleMinusOnePerXButton.bind(this);
    this.handlePlusOnePerXButton = this.handlePlusOnePerXButton.bind(this);
    this.handleShareButton = this.handleShareButton.bind(this);
    this.handleCloseShareButton = this.handleCloseShareButton.bind(this);
    this.handleMinusRunButton = this.handleMinusRunButton.bind(this);
    this.handlePlusRunButton = this.handlePlusRunButton.bind(this);
    this.handleRunsInputChange = this.handleRunsInputChange.bind(this);
    this.handleTitleInputChange = this.handleTitleInputChange.bind(this);
    this.handleDropchanceInputChange =
      this.handleDropchanceInputChange.bind(this);
    this.addDropchance = this.addDropchance.bind(this);
    this.removeDropchance = this.removeDropchance.bind(this);
    this.toggleFound = this.toggleFound.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.downloadDropchancesAsCSV = this.downloadDropchancesAsCSV.bind(this);
    this.shareLinkCopiedTimeout = null;
  }

  // Share

  handleShareButton() {
    if (this.state.shareModalActive) {
      this.copyToClipboard(this.getShareLink());

      this.setState({
        shareLinkCopied: true,
      });

      this.shareLinkCopiedTimeout && clearTimeout(this.shareLinkCopiedTimeout);
      this.shareLinkCopiedTimeout = setTimeout(
        function () {
          this.setState({
            shareLinkCopied: false,
          });
        }.bind(this),
        3000
      );
      return;
    }
    this.setState({ shareModalActive: true });
  }

  handleCloseShareButton() {
    this.shareLinkCopiedTimeout && clearTimeout(this.shareLinkCopiedTimeout);
    this.setState({
      shareLinkCopied: false,
      shareModalActive: false,
    });
  }

  getShareLink() {
    // Dropchance
    let hash = "#dc:" + this.state.dropchanceInPercent;

    // Chances
    hash += ";c1:" + this.state.runs1;
    hash += ";c2:" + this.state.runs2;
    hash += ";c3:" + this.state.runs3;

    // Runs
    hash += ";r1:" + this.state.targetProbability1;
    hash += ";r2:" + this.state.targetProbability2;
    hash += ";r3:" + this.state.targetProbability3;

    // Complete link
    return "https://dropchance.app/" + hash;
  }

  // Inputs

  handleDropchanceInPercentChange(event) {
    let value = event.target.value;
    if (parseFloat(value) > 100) {
      value = 100;
    }
    if (parseFloat(value) < 0) {
      value = 0;
    }
    this.setState({
      dropchanceInPercent: value,
      dropchanceOnePerX: Calc.convertPercentToOnePerX(value),
    });
  }

  handleDropchanceOnePerX(event) {
    let value = event.target.value;
    this.setState({
      dropchanceInPercent: Calc.convertOnePerXToPercent(value),
      dropchanceOnePerX: value,
    });
  }

  handleRunsChange(nr, event) {
    let state = {};
    state["runs" + nr] = event.target.value;
    this.setState(state);
  }

  handleTargetProbabilityChange(nr, event) {
    let state = {};
    state["targetProbability" + nr] = event.target.value;
    this.setState(state);
  }

  // Minus and plus buttons

  handleMinusPercentButton() {
    const value = parseFloat(this.state.dropchanceInPercent);

    if (value <= 0) {
      return;
    }

    if (value <= 1) {
      let floor = Math.floor(value * 10) / 10;

      if (value !== floor) {
        return this.setState({
          dropchanceInPercent: floor,
          dropchanceOnePerX: Calc.convertPercentToOnePerX(floor),
        });
      }

      floor -= 0.1;
      const nextValue = Math.round((floor + Number.EPSILON) * 1000) / 1000;

      if (nextValue <= 0) {
        return this.setState({
          dropchanceInPercent: 0,
          dropchanceOnePerX: Calc.convertPercentToOnePerX(0),
        });
      }

      return this.setState({
        dropchanceInPercent: nextValue,
        dropchanceOnePerX: Calc.convertPercentToOnePerX(nextValue),
      });
    }

    const floor = Math.floor(value);

    if (value !== floor) {
      return this.setState({
        dropchanceInPercent: floor,
        dropchanceOnePerX: Calc.convertPercentToOnePerX(floor),
      });
    }

    const nextValue = value - 1;

    if (nextValue <= 0) {
      return this.setState({
        dropchanceInPercent: 0,
        dropchanceOnePerX: Calc.convertPercentToOnePerX(0),
      });
    }

    this.setState({
      dropchanceInPercent: nextValue,
      dropchanceOnePerX: Calc.convertPercentToOnePerX(nextValue),
    });
  }

  handlePlusPercentButton() {
    const value = parseFloat(this.state.dropchanceInPercent);

    if (value >= 100) {
      return;
    }

    let ceil = Math.ceil(value);

    if (ceil >= 100) {
      return this.setState({
        dropchanceInPercent: 100,
        dropchanceOnePerX: Calc.convertPercentToOnePerX(100),
      });
    }

    if (value !== ceil) {
      return this.setState({
        dropchanceInPercent: ceil,
        dropchanceOnePerX: Calc.convertPercentToOnePerX(ceil),
      });
    }

    const nextValue = Math.round((ceil + 1 + Number.EPSILON) * 100) / 100;

    if (nextValue >= 100) {
      return this.setState({
        dropchanceInPercent: 100,
        dropchanceOnePerX: Calc.convertPercentToOnePerX(100),
      });
    }

    return this.setState({
      dropchanceInPercent: nextValue,
      dropchanceOnePerX: Calc.convertPercentToOnePerX(nextValue),
    });
  }

  handleMinusOnePerXButton() {
    const value = parseFloat(this.state.dropchanceOnePerX);

    if (value <= 1) {
      return;
    }

    if (!value || !isFinite(value)) {
      return this.setState({
        dropchanceInPercent: Calc.convertOnePerXToPercent(1),
        dropchanceOnePerX: 1,
      });
    }

    const floor = Math.floor(value);

    if (value !== floor) {
      return this.setState({
        dropchanceInPercent: Calc.convertOnePerXToPercent(floor),
        dropchanceOnePerX: floor,
      });
    }

    const nextValue = value - 1;

    if (nextValue <= 1) {
      return this.setState({
        dropchanceInPercent: Calc.convertOnePerXToPercent(1),
        dropchanceOnePerX: 1,
      });
    }

    return this.setState({
      dropchanceInPercent: Calc.convertOnePerXToPercent(nextValue),
      dropchanceOnePerX: nextValue,
    });
  }

  handlePlusOnePerXButton() {
    const value = parseFloat(this.state.dropchanceOnePerX);

    if (!value || !isFinite(value)) {
      return this.setState({
        dropchanceInPercent: Calc.convertOnePerXToPercent(1),
        dropchanceOnePerX: 1,
      });
    }

    let ceil = Math.ceil(value);

    if (value !== ceil) {
      return this.setState({
        dropchanceInPercent: Calc.convertOnePerXToPercent(ceil),
        dropchanceOnePerX: ceil,
      });
    }

    const nextValue = Math.round((ceil + 1 + Number.EPSILON) * 100) / 100;

    return this.setState({
      dropchanceInPercent: Calc.convertOnePerXToPercent(nextValue),
      dropchanceOnePerX: nextValue,
    });
  }

  // Copy button

  copyToClipboard(text) {
    var input = document.createElement("input");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 1000);
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
  }

  // Change dropchance in list

  handleDropchanceInputChange(index, event) {
    let value = event.target.value;
    const dropchances = this.getDropchances();
    if (value || parseInt(value, 10) === 0) {
      value = parseFloat(Math.max(0, Math.min(100, value)));
    }
    dropchances[index].dropchance = value;
    localStorage.setItem("dropchances", JSON.stringify(dropchances));
    this.setState({ dropchances: dropchances });
  }

  // Get and set dropchances

  getDropchances() {
    return JSON.parse(localStorage.getItem("dropchances") || "[]");
  }

  setDropchances(dropchances) {
    localStorage.setItem("dropchances", JSON.stringify(dropchances));
  }

  // Change dropchance values in list

  handleMinusRunButton(index) {
    const dropchances = this.getDropchances();
    dropchances[index].runs = Math.max(
      0,
      parseInt(dropchances[index].runs, 10) - 1
    );
    this.setDropchances(dropchances);
    this.setState({ dropchances: dropchances });
  }

  handlePlusRunButton(index) {
    const dropchances = this.getDropchances();
    dropchances[index].runs = parseInt(dropchances[index].runs, 10) + 1;
    this.setDropchances(dropchances);
    this.setState({ dropchances: dropchances });
  }

  handleRunsInputChange(index, event) {
    const value = event.target.value;
    const dropchances = this.getDropchances();
    dropchances[index].runs = value;
    this.setDropchances(dropchances);
    this.setState({ dropchances: dropchances });
  }

  handleTitleInputChange(index, event) {
    const value = event.target.value;
    const dropchances = this.getDropchances();
    dropchances[index].title = value;
    this.setDropchances(dropchances);
    this.setState({ dropchances: dropchances });
  }

  // Add drop chance to list

  addDropchance() {
    const dropchances = this.getDropchances();
    dropchances.push({ title: "", dropchance: 1, runs: 0 });
    this.setDropchances(dropchances);
    this.setState({ dropchances: dropchances });
  }

  // Remove drop chance to list

  removeDropchance(index) {
    const dropchances = this.getDropchances();
    dropchances.splice(index, 1);
    this.setDropchances(dropchances);
    this.setState({ dropchances: dropchances });
  }

  // Remove drop chance to list

  toggleFound(index) {
    const dropchances = this.getDropchances();
    dropchances[index].found = !dropchances[index].found;
    this.setDropchances(dropchances);
    this.setState({ dropchances: dropchances });
  }

  // Get the luck meter value

  getLuckMeterValue() {
    const dropchances = this.getDropchances();

    let runs = 0;
    let successes = 0;

    dropchances.forEach(function (item) {
      runs += item.runs
        ? Calc.convertRunsToOnePercent(
          parseInt(item.runs),
          parseFloat(item.dropchance)
        )
        : 0;
      successes += item.found ? 1 : 0;
    });

    const range = 3;
    const zScore = Math.min(
      range,
      Math.max(range * -1, Calc.calculateLuckiness(runs, successes))
    );
    const zScorePercent = ((zScore / range) * 100) / 2;

    if (zScore < 0) {
      return 50 + zScorePercent * -1 + "%";
    } else {
      return 50 - zScorePercent + "%";
    }
  }

  // Download dropchances as CSV

  downloadDropchancesAsCSV() {
    let csvContent =
      "data:text/csv;charset=utf-8," +
      "Item,Dropchance,Runs,Likelyhood,Found" +
      "\n";

    const dropchances = this.getDropchances();

    dropchances.forEach(function (row) {
      csvContent += '"' + (row.title || "") + '",';
      csvContent +=
        '"' + ((row.dropchance || "") + "%").replace(".", ",") + '",';
      csvContent += '"' + (row.runs || 0) + '",';
      csvContent +=
        '"' +
        Calc.dropchanceByRuns(parseFloat(row.dropchance), row.runs).replace(
          ".",
          ","
        ) +
        '",';
      csvContent += '"' + (row.found ? "YES" : "") + '"\n';
    });

    const date = new Date();

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      "drop-chances" +
      "_" +
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      ".csv"
    );
    document.body.appendChild(link);
    link.click();
  }

  // Reorder dropchance items

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const dropchances = this.getDropchances();

    const dropchancesOrdered = reorder(
      dropchances,
      result.source.index,
      result.destination.index
    );

    this.setDropchances(dropchancesOrdered);
    this.setState({ dropchances: dropchancesOrdered });
  }

  // Render

  render() {
    // Chance

    const chance1 = Calc.dropchanceByRuns(
      this.state.dropchanceInPercent,
      this.state.runs1
    );

    const chance2 = Calc.dropchanceByRuns(
      this.state.dropchanceInPercent,
      this.state.runs2
    );

    const chance3 = Calc.dropchanceByRuns(
      this.state.dropchanceInPercent,
      this.state.runs3
    );

    // Runs

    const runs1 = Calc.runsByTargetProbability(
      this.state.dropchanceInPercent,
      this.state.targetProbability1
    );

    const runs2 = Calc.runsByTargetProbability(
      this.state.dropchanceInPercent,
      this.state.targetProbability2
    );

    const runs3 = Calc.runsByTargetProbability(
      this.state.dropchanceInPercent,
      this.state.targetProbability3
    );

    // Share link
    const shareLink = this.getShareLink();

    return (
      <div className="app__wrapper">
        <header
          className={
            "header__wrapper" +
            (this.state.shareModalActive ? " share-modal-active" : "")
          }
        >
          <div className="header__container">
            <h1>
              Drop Chance
              <br />
              Calculator
            </h1>
          </div>
          <div className="share__wrapper">
            <div className="share-modal__wrapper">
              <div className="share-modal__container">
                <a href={shareLink} className="share-modal__link noselect">
                  {shareLink}
                </a>
              </div>
            </div>
            <div
              className="close-share-modal-button"
              onClick={this.handleCloseShareButton}
            >
              <div className="button__icon button__icon--close">
                <CloseSharpIcon />
              </div>
            </div>
            <div
              className={
                "share-button__container noselect" +
                (this.state.shareModalActive && this.state.shareLinkCopied
                  ? " share-button__container--copied"
                  : "")
              }
              onClick={this.handleShareButton}
            >
              <div className="share-button__text share-button__text--share">
                <div className="button__text">Share</div>
                <div className="button__icon button__icon--share">
                  <ShareSharpIcon />
                </div>
              </div>
              <div className="share-button__text share-button__text--copy">
                <div className="button__text">
                  {this.state.shareModalActive && this.state.shareLinkCopied
                    ? "Copied"
                    : "Copy"}
                </div>
                <div className="button__icon button__icon--copy">
                  {this.state.shareModalActive && this.state.shareLinkCopied ? (
                    <CheckSharpIcon />
                  ) : (
                    <FileCopySharpIcon />
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="content__wrapper">
          <div className="dropchance__wrapper">
            <div className="dropchance__container">
              <div
                className={
                  "input__button input__button--minus" +
                  (parseFloat(this.state.dropchanceInPercent) <= 0
                    ? " input__button--disabled"
                    : "")
                }
                onClick={this.handleMinusPercentButton}
              ></div>
              <div className="input__content">
                <div className="input__text"></div>
                <input
                  type="number"
                  className="textfield"
                  value={this.state.dropchanceInPercent}
                  onChange={this.handleDropchanceInPercentChange}
                  min="0"
                  max="100"
                ></input>
                <div className="input__text input__text--percent">%</div>
              </div>
              <div
                className={
                  "input__button input__button--plus" +
                  (parseFloat(this.state.dropchanceInPercent) >= 100
                    ? " input__button--disabled"
                    : "")
                }
                onClick={this.handlePlusPercentButton}
              ></div>
            </div>
            <div className="dropchance__container">
              <div
                className={
                  "input__button input__button--minus" +
                  (parseFloat(this.state.dropchanceOnePerX) <= 1
                    ? " input__button--disabled"
                    : "")
                }
                onClick={this.handleMinusOnePerXButton}
              ></div>
              <div className="input__content">
                <div className="input__text input__text--one-per">1 in</div>
                <input
                  type="number"
                  className="textfield"
                  value={this.state.dropchanceOnePerX}
                  onChange={this.handleDropchanceOnePerX}
                  min="1"
                ></input>
                <div className="input__text"></div>
              </div>
              <div
                className="input__button input__button--plus"
                onClick={this.handlePlusOnePerXButton}
              ></div>
            </div>
          </div>
          <div className="headline">
            Chance for the item to drop at least once
          </div>
          <div className="output__wrapper">
            <div className="output__container">
              <div className="output__cell">
                <div className="output__header">
                  <input
                    type="number"
                    className="textfield"
                    value={this.state.runs1}
                    onChange={(e) => {
                      this.handleRunsChange(1, e);
                    }}
                    min="1"
                  ></input>
                  <div className="output__text">runs</div>
                </div>
                <div
                  className={
                    "output__value" +
                    (["<", ">"].indexOf(chance1.toString().charAt(0)) > -1
                      ? " has-special-first-character"
                      : "")
                  }
                >
                  <span>{chance1}</span>
                </div>
              </div>
              <div className="output__cell">
                <div className="output__header">
                  <input
                    type="number"
                    className="textfield"
                    value={this.state.runs2}
                    onChange={(e) => {
                      this.handleRunsChange(2, e);
                    }}
                    min="1"
                  ></input>
                  <div className="output__text">runs</div>
                </div>
                <div
                  className={
                    "output__value" +
                    (["<", ">"].indexOf(chance2.toString().charAt(0)) > -1
                      ? " has-special-first-character"
                      : "")
                  }
                >
                  <span>{chance2}</span>
                </div>
              </div>
              <div className="output__cell">
                <div className="output__header">
                  <input
                    type="number"
                    className="textfield"
                    value={this.state.runs3}
                    onChange={(e) => {
                      this.handleRunsChange(3, e);
                    }}
                    min="1"
                  ></input>
                  <div className="output__text">runs</div>
                </div>
                <div
                  className={
                    "output__value" +
                    (["<", ">"].indexOf(chance3.toString().charAt(0)) > -1
                      ? " has-special-first-character"
                      : "")
                  }
                >
                  <span>{chance3}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="headline">
            Expected runs for the item to drop at least once
          </div>
          <div className="output__wrapper">
            <div className="output__container">
              <div className="output__cell">
                <div className="output__header">
                  <input
                    type="number"
                    className="textfield"
                    value={this.state.targetProbability1}
                    onChange={(e) => {
                      this.handleTargetProbabilityChange(1, e);
                    }}
                    min="1"
                    max="100"
                  ></input>
                  <div className="output__text output__text--percent">%</div>
                </div>
                <div
                  className={
                    "output__value" +
                    (runs1 === "∞" ? " is-infinity" : "") +
                    (["<", ">"].indexOf(runs1.toString().charAt(0)) > -1
                      ? " has-special-first-character"
                      : "")
                  }
                >
                  <span>{runs1}</span>
                </div>
              </div>
              <div className="output__cell">
                <div className="output__header">
                  <input
                    type="number"
                    className="textfield"
                    value={this.state.targetProbability2}
                    onChange={(e) => {
                      this.handleTargetProbabilityChange(2, e);
                    }}
                    min="1"
                    max="100"
                  ></input>
                  <div className="output__text output__text--percent">%</div>
                </div>
                <div
                  className={
                    "output__value" +
                    (runs2 === "∞" ? " is-infinity" : "") +
                    (["<", ">"].indexOf(runs2.toString().charAt(0)) > -1
                      ? " has-special-first-character"
                      : "")
                  }
                >
                  <span>{runs2}</span>
                </div>
              </div>
              <div className="output__cell">
                <div className="output__header">
                  <input
                    type="number"
                    className="textfield"
                    value={this.state.targetProbability3}
                    onChange={(e) => {
                      this.handleTargetProbabilityChange(3, e);
                    }}
                    min="0"
                    max="100"
                  ></input>
                  <div className="output__text output__text--percent">%</div>
                </div>
                <div
                  className={
                    "output__value" +
                    (runs3 === "∞" ? " is-infinity" : "") +
                    (["<", ">"].indexOf(runs3.toString().charAt(0)) > -1
                      ? " has-special-first-character"
                      : "")
                  }
                >
                  <span>{runs3}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="headline-highlight">My drop chances</div>
          <div className="dropchance-items__wrapper">
            <div className="dropchance-items__header">
              <div className="dropchance-item__col dropchance-item__col--header dropchance-item__col--handle"></div>
              <div className="dropchance-item__col dropchance-item__col--header dropchance-item__col--item">
                Item
              </div>
              <div className="dropchance-item__col dropchance-item__col--header dropchance-item__col--dropchance">
                Chance
              </div>
              <div className="dropchance-item__col dropchance-item__col--header dropchance-item__col--runs">
                Runs
              </div>
              <div className="dropchance-item__col dropchance-item__col--header dropchance-item__col--likelihood">
                Likelihood
              </div>
              <div className="dropchance-item__col dropchance-item__col--header dropchance-item__col--buttons"></div>
            </div>
            <div className="dropchance-items__container">
              {!this.state.dropchances.length ? (
                <div className="dropchance-items__empty">
                  Click "<span className="dropchance-items__empty-text dropchance-items__empty-text--item">Add item</span><span className="dropchance-items__empty-text dropchance-items__empty-text--plus">+</span>" to track the dropchances of a specific
                  item.
                </div>
              ) : (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {this.state.dropchances.map((item, index) => {
                          const dropchance = Calc.dropchanceByRuns(
                            parseFloat(item.dropchance),
                            item.runs
                          );

                          return (
                            <Draggable
                              key={"draggable" + index}
                              draggableId={"draggable" + index}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className={
                                    "dropchance-item__container" +
                                    (item.found ? " dropchance-item-found" : "")
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div
                                    {...provided.dragHandleProps}
                                    className="dropchance-item__col dropchance-item__col--handle"
                                  >
                                    <DragHandleSharpIcon />
                                  </div>
                                  <div className="dropchance-item__col dropchance-item__col--item">
                                    <input
                                      type="text"
                                      spellCheck={false}
                                      className={
                                        "textfield textfield--small" +
                                        (item.title.trim() === ""
                                          ? ""
                                          : " textfield--hidden")
                                      }
                                      value={item.title}
                                      onChange={(event) => {
                                        this.handleTitleInputChange(
                                          index,
                                          event
                                        );
                                      }}
                                    ></input>
                                  </div>
                                  <div className="dropchance-item__col dropchance-item__col--dropchance">
                                    <input
                                      type="number"
                                      min="0"
                                      max="100"
                                      className={
                                        "textfield textfield--small" +
                                        (item.found ? " textfield--hidden" : "")
                                      }
                                      readOnly={item.found ? "readonly" : ""}
                                      value={item.dropchance}
                                      onChange={(event) => {
                                        this.handleDropchanceInputChange(
                                          index,
                                          event
                                        );
                                      }}
                                    ></input>
                                    <div className="dropchance-item__percent">
                                      %
                                    </div>
                                  </div>
                                  <div className="dropchance-item__col dropchance-item__col--runs">
                                    <div className="dropchance-item__input-container dropchance-item__input-container--runs">
                                      <div
                                        className={
                                          "input__button input__button--small input__button--minus" +
                                          (parseFloat(
                                            this.state.dropchanceInPercent
                                          ) <= 0
                                            ? " input__button--disabled"
                                            : "")
                                        }
                                        onClick={() => {
                                          this.handleMinusRunButton(index);
                                        }}
                                      ></div>
                                      <input
                                        type="number"
                                        min="0"
                                        max="100"
                                        className={
                                          "textfield textfield--small" +
                                          (item.found
                                            ? " textfield--hidden"
                                            : "")
                                        }
                                        readOnly={item.found ? "readonly" : ""}
                                        value={item.runs}
                                        onChange={(event) => {
                                          this.handleRunsInputChange(
                                            index,
                                            event
                                          );
                                        }}
                                      ></input>
                                      <div
                                        className={
                                          "input__button input__button--small input__button--plus" +
                                          (parseFloat(
                                            this.state.dropchanceInPercent
                                          ) >= 100
                                            ? " input__button--disabled"
                                            : "")
                                        }
                                        onClick={() => {
                                          this.handlePlusRunButton(index);
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="dropchance-item__col dropchance-item__col--likelihood">
                                    <div className="dropchance-item__likelihood-text">
                                      {dropchance}
                                    </div>
                                    <div className="dropchance-item__likelihood-graph">
                                      <span style={{ left: dropchance }}></span>
                                    </div>
                                  </div>
                                  <div className="dropchance-item__col dropchance-item__col--buttons">
                                    <div
                                      className="dropchance-item__icon-container dropchance-item__icon-container--checkbox"
                                      onClick={() => {
                                        this.toggleFound(index);
                                      }}
                                    >
                                      <CheckBoxOutlineBlankSharpIcon className="dropchance-item__icon--checkbox-outline" />
                                      <CheckBoxSharpIcon className="dropchance-item__icon--checkbox" />
                                    </div>
                                    <AlertDialog
                                      itemTitle={item.title}
                                      onConfirm={() => {
                                        this.removeDropchance(index);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
            <div className="dropchance-items__footer">
              <div className="dropchance-item__buttons">
                <div
                  className="button dropchance-item__button dropchance-item__button--add"
                  onClick={this.addDropchance}
                >
                  <span>Add item</span>
                </div>
                {this.state.dropchances.length ? (
                  <div
                    className="button dropchance-item__button dropchance-item__button--download"
                    onClick={this.downloadDropchancesAsCSV}
                  >
                    <span>Download CSV</span>
                    <DownloadSharpIcon />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {this.state.dropchances.length ? (
                <div className="dropchance-item__luck-meter-container">
                  <div className="dropchance-item__luck-meter-text">
                    Luck Meter
                  </div>
                  <div className="dropchance-item__luck-meter-graph">
                    <div className="dropchance-item__likelihood-graph">
                      <span style={{ left: this.getLuckMeterValue() }}></span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </main>
        <footer className="footer__wrapper">
          Created by{" "}
          <a href="https://stephanwagner.me" rel="noreferrer" target="_blank">
            Stephan Wagner
          </a>
        </footer>
      </div>
    );
  }
}

export default App;
