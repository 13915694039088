// Return an valid value for probability

function parseProbability(probability) {
  return Math.min(100, Math.max(0, parseFloat(probability) || 0));
}

// Show a floating number in a way it makes sense
// 55.00 > 50
// 55.20 > 55.20
// 55.20943 > 55,21
// 0.000055 > 0,000055

export function toFixed(nr) {
  nr = parseFloat(nr);

  if (nr > 99.99 && nr < 100) {
    return ">99.99";
  }

  if (nr < 0.001 && nr > 0) {
    return "<0.001";
  }

  if (nr < 1) {
    return Math.round((parseFloat(nr) + Number.EPSILON) * 1000) / 1000;
  }

  return Math.round((parseFloat(nr) + Number.EPSILON) * 100) / 100;
}

export function probabilityToPercent(nr) {
  return toFixed(nr * 100) + "%";
}

export function probabilityByRuns(probability, runs) {
  return 1 - Math.pow(1 - probability, runs);
}

export function dropchanceByRuns(probability, runs) {
  probability = parseProbability(probability);
  runs = Math.max(0, parseInt(runs) || 0);
  return probabilityToPercent(probabilityByRuns(probability / 100, runs));
}

export function convertPercentToOnePerX(probability) {
  probability = parseProbability(probability);
  return toFixed((1 / probability) * 100);
}

export function convertOnePerXToPercent(nr) {
  if (!nr) {
    return 0;
  }

  nr = Math.max(1, parseFloat(nr));
  const value = (1 / nr) * 100;

  if (value < 0.001) {
    return Math.round((value + Number.EPSILON) * 10000) / 10000;
  }

  if (value < 0.01) {
    return Math.round((value + Number.EPSILON) * 1000) / 1000;
  }

  return Math.round((value + Number.EPSILON) * 100) / 100;
}

// The expeted amount of runs by target chance

export function runsByTargetProbability(probability, targetProbability) {
  probability = parseProbability(probability) / 100;
  targetProbability = parseProbability(targetProbability) / 100;
  const runs = Math.log(1 - targetProbability) / Math.log(1 - probability);

  if (!isFinite(runs)) {
    return "∞";
  }

  if (runs > 999000000000) {
    return ">999 B";
  }

  if (runs > 100000000000) {
    return ">" + runs.toString().substr(0, 3) + " B";
  }

  if (runs === 100000000000) {
    return "100 B";
  }

  if (runs > 10000000000) {
    return ">" + runs.toString().substr(0, 2) + " B";
  }

  if (runs === 10000000000) {
    return "10 B";
  }

  if (runs > 1000000000) {
    return ">" + runs.toString().substr(0, 1) + " B";
  }

  if (runs === 1000000000) {
    return "1 B";
  }

  if (runs > 100000000) {
    return ">" + runs.toString().substr(0, 3) + " M";
  }

  if (runs === 100000000) {
    return "100 M";
  }

  if (runs > 10000000) {
    return ">" + runs.toString().substr(0, 2) + " M";
  }

  if (runs === 10000000) {
    return "10 M";
  }

  return Math.max(1, Math.ceil(runs));
}

// Convert probability to one percent

export function convertRunsToOnePercent(runs, probability) {
  return runs * probability;
}

// Calculate luckyness

export function calculateLuckiness(runs, successes) {
  const probability = 0.01;

  const zScore =
    (successes - runs * probability) /
    Math.pow(runs * probability * (1 - probability), 1 / 2);

  return zScore;
}
